import './styles.scss';
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import park from './routes/park';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
  /** Park page */
  park,
});

/** Load Events */

document.addEventListener("DOMContentLoaded", () => {
	routes.loadEvents()
});
