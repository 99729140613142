import Siema from "siema";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default {
	init() {
		
		const headerSlider = new Siema({
			selector: "#headerSlider",
			duration: 500,
			easing: "ease-out",
			perPage: 1,
			startIndex: 0,
			draggable: false,
			multipleDrag: false,
			threshold: 20,
			loop: true,
			rtl: false,
			onInit: function() {
				
				this.innerElements[0].classList.add("slider__item--active");
				
			},
			onChange: function() {
				
				clearInterval(sliderAutoPlay);
				sliderAutoPlay = setInterval(nextSlide, 4000);
				
				const dots = document.querySelectorAll(".slider-nav__btn");
				
				dots.forEach((slide, i) => {
					let addOrRemove = i === this.currentSlide ? "add" : "remove";
					dots[i].classList[addOrRemove]("slider-nav__btn--active");
					this.innerElements[i].classList[addOrRemove]("slider__item--active");
				});
				
				const sliderNumber = document.querySelector(".slider-nav__no");
				
				sliderNumber.textContent = `0${this.currentSlide + 1}.`;
				
			}
		});
		
		Siema.prototype.dots = function () {
			
			for (let i = 0; i < this.innerElements.length; i++) {
				
				const navWrapper = document.querySelector(".slider-nav");
				const btn = document.createElement("button");
				
				btn.classList.add("btn", "slider-nav__btn");
				btn.addEventListener("click", () => {
					this.goTo(i);
				});
				
				navWrapper.appendChild(btn);
				
				const dots = Array.prototype.slice.call(document.querySelectorAll(".slider-nav__btn"));
				dots[0].classList.add("slider-nav__btn--active");
				
			}
		};
		
		headerSlider.dots();
		
		const nextSlide = () => {
			headerSlider.next();
		};
		
		let sliderAutoPlay = setInterval(nextSlide, 5000);
		
		/* Animacje */
		
		const headerMoreBtn = document.querySelector(".header__more");
		
		headerMoreBtn.addEventListener("click", (e) => {
			e.preventDefault();
			gsap.to(window, { duration: 1, scrollTo: { y: ".intro", offsetY: 160 }});
		});
    
    const links = document.querySelectorAll('.park-nav__link');
    
    links.forEach(link => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        
        if (targetElement) {
          gsap.to(window, {
            duration: 1,
            scrollTo: {
              y: targetElement,
              offsetY: 160,
            }
          });
        } else {
          console.warn(`Element ${targetId} not found on the page.`);
        }
      });
    });
		
		gsap.utils.toArray("section").forEach((el, i) => {
			gsap.to(el, {
				scrollTrigger: {
					trigger: el,
					start: "-20% top",
					scrub: true
				}
			});
			gsap.fromTo(el.children, { y: 100, opacity: 0 }, { y: 0, opacity: 1, stagger: .3, duration: 1, scrollTrigger: {
				trigger: el,
				start: "top 80%"
			}});
		});
	},
	finalize() {},
};
