export default {
	init() {
		
		document.body.classList.add("body--loaded");
		
		const navigation = document.querySelector(".nav");
		const navigationList = document.querySelector(".nav-links");
		const menuBtn = document.querySelector(".burger");
		
		window.onscroll = () => {
			if (window.scrollY >= navigation.getBoundingClientRect().height) {
				navigation.classList.add("nav--fixed");
			} else {
				navigation.classList.remove("nav--fixed");
			}
		};
		
		const toggleMenu = () => {
			
			navigationList.classList.toggle("nav-links--is-open");
			menuBtn.classList.toggle("burger--animate");
			
		};
		
		menuBtn.addEventListener("click", toggleMenu);
		
		/* Cookies */
		
		const cookies = document.querySelector(".cookies");
		
		if (!localStorage.cookiesAccepted) {
			cookies.classList.add("cookies--visible");
		}
		
		const setCookies = (e) => {
			e.preventDefault();
			localStorage.cookiesAccepted = true;
			cookies.classList.remove("cookies--visible");
		};
		
		document.querySelector(".cookies__btn").addEventListener("click", setCookies);
		
	},
	finalize() {},
};